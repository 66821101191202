import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import Box from "@material-ui/core/Box";
import styled from "styled-components";
import WrapTypo from "../components/WrapTypo";
import Grid from "@material-ui/core/Grid";
import Img from "gatsby-image";

const Profile = () => {
  const data = useStaticQuery(graphql`
    query {
      anj: file(relativePath: { eq: "anjusImgProfile.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      product: file(relativePath: { eq: "product@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productDetail: file(relativePath: { eq: "productDetail.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vector: file(relativePath: { eq: "vector_media.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Box width="90%" maxWidth="1200px" m={"auto"} mt={[6, 10, 20, 20, 20]}>
        <Grid container justify="space-between">
          <Grid item xs={12} sm={6}>
            <WrapTypo fs="36px" color="rgba(128, 161, 221, 1)">
              PROFILE
            </WrapTypo>
            <WrapTypo variant="h1" fs="50px" mt="32px" xsfs="40px">
              石山 アンジュ
            </WrapTypo>
            <WrapTypo fs="16px" lh="24px" ls="10%">
              Anju　Ishiyama
            </WrapTypo>
            <WrapTypo mt="32px" fs="18px" lh="200%" Yumincho xsfs="14px">
              一般社団法人Public Meets Innovation代表
              <br />
              デジタル庁シェアリングエコノミー伝道師
              <br />
              一般社団法人シェアリングエコノミー協会 代表理事
              <br />
              株式会社パブリックボーダーズ 代表取締役
            </WrapTypo>
            <WrapTypo
              Notosans
              fs="14px"
              lh="180%"
              mt="32px"
              xsfs="14px"
              style={{ textAlign: "justify" }}
              mb="24px"
            >
              1989年生まれ。「シェア(共有)」の概念に親しみながら育つ。シェアリングエコノミーを通じた新しいライフスタイルを提案する活動を行うほか、政府と民間のパイプ役として規制緩和や政策推進にも従事。
              2018年10月ミレニアル世代のシンクタンク一般社団法人Public Meets
              Innovationを設立。
              新しい家族の形「拡張家族」を掲げるコミュニティ拡張家族Cift
              家族代表。世界経済フォーラム Global Future Council
              Japanメンバー。USEN-NEXT HOLDINGS
              社外取締役。ほかに「羽鳥慎一モーニングショー」木曜レギュラー、「真相報道バンキシャ！」「アサデス。」「ドデスカ！」にコメンテーターとして定期出演。2012年国際基督教大学(ICU)卒。新卒で(株)リクルート入社、その後(株)クラウドワークス経営企画室を経て現職。デジタル庁シェアリングエコノミー伝道師。大分と東京の二拠点生活。著書に「シェアライフ-新しい社会の新しい生き方-」、新著に「多拠点ライフ-分散する生き方-」Forbes
              JAPAN「日本のルールメイカー30人」に選出。米づくり3年目、特技は大人数料理を作ること。
            </WrapTypo>

            <Link
              to="/profile"
              style={{
                borderBottom: "1px solid #717171",
                color: "#717171",
                marginTop: "16px",
              }}
            >
              <Box display="flex" alignItems="center">
                <WrapTypo
                  Notosans
                  fs="14px"
                  lh="180%"
                  xsfs="14px"
                  style={{
                    textAlign: "left",
                    borderBottom: "1px solid #717171",
                  }}
                >
                  もっと詳しく読む
                </WrapTypo>
                <Img
                  fluid={data.vector.childImageSharp.fluid}
                  style={{ width: "100px" }}
                />
              </Box>
            </Link>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Img
              style={{ width: "100%", margin: "auto" }}
              fluid={data.anj.childImageSharp.fluid}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Profile;
