import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import WrapTypo from "../components/WrapTypo";
import Grid from "@material-ui/core/Grid";
import Img from "gatsby-image";
import { Button, Hidden } from "@material-ui/core";
import { createClient } from "microcms-js-sdk";
import noImage from "../images/media_and_news_no_image.png";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { makeStyles } from "@material-ui/core/styles";

const Profile = (props) => {
  const { isTop = true } = props;
  const data = useStaticQuery(graphql`
    query {
      media1: file(relativePath: { eq: "media1.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      media2: file(relativePath: { eq: "media2.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      media3: file(relativePath: { eq: "media3.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      media4: file(relativePath: { eq: "media4.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      media5: file(relativePath: { eq: "media5.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      media6: file(relativePath: { eq: "media6.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      media7: file(relativePath: { eq: "media7.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      media8: file(relativePath: { eq: "media8.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      media9: file(relativePath: { eq: "media9.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      vector: file(relativePath: { eq: "vector_media.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const content = [
    {
      img: data.media1,
      url: "https://wired.jp/2020/12/30/share-summit-2020/",
      date: "2020.12.30",
      media: "WIRED掲載",
      title:
        "ウェルビーイングの次なるフェーズへ：「SHARE SUMMIT 2020」で語られたこれからの豊かさについて",
    },
    {
      img: data.media2,
      url: "https://share.jp/interview/share2021/",
      date: "2020.12.30",
      media: "Share.jp掲載",
      title:
        "石山アンジュさんに聞く！2021年のシェアリングエコノミーのトレンドとは？",
    },
    {
      img: data.media3,
      url: "https://conf-x2020.peatix.com/?lang=ja",
      date: "2020.12.11",
      media: "Conference X",
      title: "コロナ禍を生き抜く「クロス」戦略と最新事例",
    },
    {
      img: data.media4,
      url: "https://genic-web.com/articles/3336992",
      date: "2020.12.7",
      media: "GENIC 1月号",
      title: "デュアルライフ&移住ライフ 掲載",
    },
    {
      img: data.media5,
      url: "https://www3.nhk.or.jp/news/special/news_seminar/senpai/senpai62/",
      date: "2020.11.27",
      media: "NHK",
      title:
        "大学生とつくる就活応援ニュースゼミ「教えて先輩！」“シェア伝道師”「使命感」を持つこと",
    },
    {
      img: data.media6,
      url: "https://listentoheronline.peatix.com/view",
      date: "2020.11.21",
      media: "Listen to Her",
      title: "今こそ聞きたいビジョナリー・ウーマンの話",
    },
    {
      img: data.media7,
      url: "https://www.tv-asahi.co.jp/m-show/",
      date: "2020.11.18",
      media: "テレビ朝日",
      title: "羽鳥慎一 モーニングショー出演",
    },
    {
      img: data.media8,
      url: "https://newspicks.com/movie-series/28?movieId=967",
      date: "2020.11.17",
      media: "WEEKLY OCHIAI",
      title: "家族とは何か？「家族のかたち」を考える",
    },
    {
      img: data.media9,
      url: "https://publications.asahi.com/ecs/12.shtml",
      date: "2020.10.26",
      media: "AERA",
      title: "「21世紀を作るニッポン人名鑑」",
    },
  ];

  const news = [
    {
      date: "2021.6.3,10,17,24",
      media: "テレビ朝日",
      title: "羽鳥慎一 モーニングショー レギュラー出演",
      link: "https://www.tv-asahi.co.jp/m-show/",
    },
    {
      date: "2021.6.28",
      media: "USEN-NEXT HOLDINGS 主催イベント",
      title: "～新しい時代のお店のあり方 「店舗DX」で、お店が街が進化する～",
      link: "https://usen-next.co.jp/info/2021/06/hd-huff-dx.html",
    },
    {
      date: "2021.6.27",
      media: "日経ヴェリタス",
      title:
        "働き方ギグ・テレの先――コロナで加速するシェアリングエコノミー、シェアリングエコノミー協会常任理事石山アンジュ氏。",
      link: "",
    },
    {
      date: "2021.6.15",
      media: "野村不動産 新規事業開発チーム 主催イベント",
      title: "Be a Starter Vol.４：シェアするライフスタイルとは？",
      link: "https://www.h1t-web.com/news/69.html",
    },
    {
      date: "2021.6.7",
      media: "渋谷のラジオ出演",
      title: "",
      link: "",
    },
    {
      date: "2021.6.2",
      media: "日テレNEWS24",
      title: "ミレニアル世代の声を届け、未来を共に創る",
      link: "https://www.news24.jp/articles/2021/06/02/07882653.html",
    },
    {
      date: "2021.5.6,13,20,27",
      media: "テレビ朝日",
      title: "羽鳥慎一 モーニングショー レギュラー出演",
      link: "https://www.tv-asahi.co.jp/m-show/",
    },
    {
      date: "2021.5.25",
      media: "BUSINESS INSIDER 掲載",
      title: "｢拡張家族｣を信じる石山アンジュが、たった一人と結婚した理由",
      link: "https://www.businessinsider.jp/post-234842",
    },
    {
      date: "2021.5.16",
      media: "日本テレビ",
      title: "真相報道 バンキシャ！出演",
      link: "https://www.ntv.co.jp/bankisha/",
    },
    {
      date: "2021.5.4",
      media: "日本経済新聞 電子版",
      title:
        "私も迷い、道を探した　挑む30代先輩　新社会人への言葉：日本経済新聞",
      link: "https://www.nikkei.com/article/DGXZQOUD239DH0T20C21A4000000/",
    },
    {
      date: "2021.4.27",
      media: "JDIR",
      title: "恋愛するように社会を作る。これからの信頼社会を考える「オニワラ」",
      link: "https://jbpress.ismedia.jp/articles/-/65017",
    },
    {
      date: "2021.4.11",
      media: "日本テレビ",
      title: "真相報道 バンキシャ！出演",
      link: "https://www.ntv.co.jp/bankisha/",
    },
    {
      date: "2021.4.26",
      media: "日本経済新聞 ",
      title: "石山アンジュさん　地球人として育ててくれた父。それでも親子",
      link: "https://www.nikkei.com/article/DGXZQOFE312V70R30C21A3000000/",
    },

    {
      date: "2021.4.26",
      media: "JDIR",
      title:
        "アフターコロナではシェアリングがどう変化するか。専有と共有のバランスがライフスタイルの鍵になる",
      link: "https://jbpress.ismedia.jp/articles/-/65002",
    },
    {
      date: "2021.4.16",
      media: "慶應義塾大学大学院",
      title: "SDM特別講義 2021年度 春学期。シェアから考えるこれからの豊かさ",
      link: "http://www.sdm.keio.ac.jp/education/feature/sdmspecial/",
    },

    {
      date: "2021.4.1,8,15,22,29",
      media: "テレビ朝日",
      title: "羽鳥慎一 モーニングショー レギュラー出演",
      link: "https://www.tv-asahi.co.jp/m-show/",
    },
    {
      date: "2021.3.30",
      media: "日経doors",
      title: "石山アンジュ　家族の概念を広げた先に見えた結婚",
      link:
        "https://doors.nikkei.com/atcl/column/19/112500147/032600015/?i_cid=nbpdoors_sied_pt_new",
    },
    {
      date: "2021.3.23",
      media:
        "〜大阪・関西万博「TEAM EXPO」プログラム Sustainable Innovation Lab キックオフイベント〜",
      title: "サステナブル・イノヴェイションで不確実性の未来を拓く",
      link: "https://sustainable-innovation.peatix.com/",
    },

    {
      date: "2021.3.23",
      media: "朝日新聞 広告",
      title: "２１世紀をつくるニッポン人名鑑",
      link: "　",
    },
    {
      date: "2021.3.20",
      media: "コミュニティフォーラム２０２１ 基調講演",
      title: "",
      link: "https://communityforum2021.peatix.com/view",
    },
    {
      date: "2021.3.19",
      media: "先駆的な Oitan　社会活動家石山 アンジュ",
      title: "大分は夢の国？シェアの伝道師が豊後大野に惚れたワケ",
      link: "https://minnade.oita.jp/oitan/vol18/",
    },
    {
      date: "2021.3.19",
      media: "【枡田絵理奈のワンピース・ライフ#55】",
      title: "【シェアライフ】日本の課題と解決策とは？",
      link:
        "https://www.youtube.com/watch?v=4ghJXnrP5-w&list=PLxKSPjPWc5KtGJivHYeZa7BM4_aRhTXDJ&index=3",
    },
    {
      date: "2021.3.18",
      media: "『専有と共有のバランス ～分け合う、支え合う心の未来』",
      title: "powered by IBM Future Design Lab. #3",
      link: "https://eventregist.com/e/oniwara_3",
    },
    {
      date: "2021.3.17",
      media: "ビジネスID",
      title:
        "61％が見直しを検討。ザイマックス、オカムラらが考える「新・働く場所」の選び方",
      link: "https://www.sbbit.jp/article/cont1/55318",
    },
    {
      date: "2021.3.16",
      media: "MAGMOE",
      title:
        "社会活動家の石山アンジュさんが Chromebook を体験。シェアすることで、家族や仲間ともっと心地よくつながれる。",
      link: "https://www.magmoe.com/22149/madame-figaro-japon/2021-03-16/",
    },
    {
      date: "2021.3.14",
      media: "FM AICHI",
      title: "枡田絵理奈のワンピースライフ",
      link: "https://fma.co.jp/f/prg/onepiece/",
    },
    {
      date: "2021.3.12",
      media: "Yukemuri Startup Summit 2021",
      title: "",
      link: "https://startup.oita.jp/event/yss-20210212/",
    },

    {
      date: "2021.3.12",
      media: "【枡田絵理奈のワンピース・ライフ#54】",
      title: "【シェアリングエコノミー】共同生活から得た経験とは？",
      link: "https://www.youtube.com/watch?v=zIxrVp78Ibw",
    },

    {
      date: "2021.3.12",
      media: "WIRED",
      title: "「FOOD: re-generative」 〜食トリヴィア50〜",
      link: "https://wired.jp/magazine/vol_40/",
    },

    {
      date: "2021.3.9",
      media: "テレビ朝日",
      title: "羽鳥慎一 モーニングショー出演",
      link: "https://www.tv-asahi.co.jp/m-show/",
    },

    {
      date: "2021.3.9",
      media: "アデコ株式会社 Power of Work",
      title:
        "“シェア”をする生き方で、人生が豊かになる。 シェアリングエコノミー伝道師　石山アンジュ氏",
      link: "https://www.adeccogroup.jp/power-of-work/191",
    },
    {
      date: "2021.3.7",
      media: "FM AICHI",
      title: "枡田絵理奈のワンピースライフ",
      link: "https://fma.co.jp/f/prg/onepiece/",
    },
    {
      date: "2021.3.6",
      media: "OPEN OITA FORUM",
      title: "「実践者たちが語る『人材不足』解決の糸口」",
      link: "https://open-oita.jp/open-oita-forum/",
    },

    {
      date: "2021.3.6",
      media: "SOCIAL WORKERS TALK 2020「福祉の周辺」",
      title: "Vol.3　家族と福祉 ",
      link: "https://swlab.jp/event/145",
    },

    {
      date: "2021.2.28",
      media: "SHARE by WHERE 登壇",
      title: "人口減少時代の幸福論！地域と人のウェルビーイング",
      link: "https://share.where.inc/",
    },
    {
      date: "2021.2.23",
      media: "at Will Work 登壇",
      title:
        "働き方を考えるカンファレンス2021「働くのこれから 」働く場所や環境は変化したのか",
      link: "https://www.atwill.work/conference2021/",
    },
    {
      date: "2021.2.23",
      media: "テレビ朝日",
      title: "羽鳥慎一 モーニングショー出演",
      link: "https://www.tv-asahi.co.jp/m-show/",
    },
    {
      date: "2021.2.22",
      media: "HHK 視点・論点",
      title: "「シェアでつながる社会に」",
      link: "https://www.nhk.or.jp/kaisetsu-blog/400/444088.html",
    },
    {
      date: "2021.2.20",
      media: "Newspicks",
      title: "【論考】シェアリングエコノミーの「信頼」をどう作るか",
      link: "https://newspicks.com/news/5620106/body/?ref=user_4120929",
    },
    {
      date: "2021.2.9",
      media: "テレビ朝日",
      title: "羽鳥慎一 モーニングショー出演",
      link: "https://www.tv-asahi.co.jp/m-show/",
    },
    {
      date: "2021.2.8",
      media: "シティラボ東京",
      title:
        "【Event Report】重松健×石山アンジュ×重松大輔「東京のワクワクする未来を考える」",
      link:
        "https://citylabtokyo.jp/2021/02/08/210127-event-shigematsu-ishiyama/",
    },
    {
      date: "2021.2.5",
      media: "SUMAU掲載",
      title:
        "「常に選択肢を持つことが豊かさにつながる」　シェアリングエコノミー活動家　石山アンジュさん",
      link: "https://sumau.com/2021-n/article/210",
    },
    {
      date: "2021.2.5",
      media: "ABEMA NEWS",
      title: "拡張家族って何だ",
      link: "https://abema.tv/video/episode/89-66_s99_p2569",
    },
    {
      date: "2021.1.27",
      media: "",
      title:
        "建築家 重松健×社会活動家 石山アンジュxスペースマーケット代表取締役社長 重松大輔 「東京のワクワクする未来を考える」 produced by シティラボ東京",
      link: "https://citylabtokyo-wakuwaku6.peatix.com/",
    },
    {
      date: "2021.1.23",
      media: "GENIC 1月号 web掲載",
      title:
        "【旅するように暮らすデュアルライフ＆移住ライフ ＃４】石山アンジュ",
      link: "https://genic-web.com/articles/6404056",
    },
    {
      date: "2021.1.12",
      media: "テレビ朝日",
      title: "羽鳥慎一 モーニングショー出演",
      link: "https://www.tv-asahi.co.jp/m-show/",
    },
    {
      date: "2021.1.11",
      media: "NPO法人CRファクトリー イベント登壇",
      title: "",
      link: "",
    },

    {
      date: "2021.1.7",
      media: "読売新聞 朝刊 掲載",
      title: "［明日を築く］連帯力　信頼　孤立防ぎ、幸せ育む　石山アンジュさん",
      link: "",
    },

    {
      date: "2021.1.3",
      media: "Harper's BAZAAR web掲載",
      title:
        "【対談】社会活動家・石山アンジュ＆ホテルプロデューサー・龍崎翔子…「コロナ時代に考える“豊かさの新しい指針”」",
      link:
        "https://www.harpersbazaar.com/jp/lifestyle/womens-life/a35072014/good-lifte-good-future-210103-hbr/",
    },
    {
      date: "2020.12.30",
      media: "WIRED",
      title:
        "ウェルビーイングの次なるフェーズへ：「SHARE SUMMIT 2020」で語られたこれからの豊かさについて",
      link: "https://wired.jp/2020/12/30/share-summit-2020/",
    },
    {
      date: "2020.12.30",
      media: "Share.jp",
      title:
        "石山アンジュさんに聞く！ 2021年のシェアリングエコノミーのトレンドとは？",
      link: "https://share.jp/interview/share2021/",
    },
    {
      date: "2020.12.19",
      media: "九産大講演 登壇",
      title: "",
      link: "",
    },
    {
      date: "2020.12.18",
      media: "Share.jp",
      title:
        "2020年のシェアリングエコノミー経済効果は2兆円越え！ 新型コロナウィルスで新たな活用の広がり、SDGsへも貢献",
      link: "https://share.jp/news/marketresearch2020/",
    },
    {
      date: "2020.12.14",
      media: "",
      title:
        "CIC LIVE - スタートアップの法務とルールメイキング by 齋藤 貴弘・南 知果 ゲスト出演",
      link: "https://www.youtube.com/watch?v=ehE2lM82lMg",
    },
    {
      date: "2020.12.11",
      media: "Social Transformation 登壇",
      title: "Conference X 〜コロナ禍を生き抜く「クロス」戦略と最新事例〜",
      link: "https://conf-x2020.peatix.com/?lang=ja",
    },
    {
      date: "2020.12.7",
      media: "GENIC 1月号",
      title: "デュアルライフ&移住ライフ 掲載",
      link: "https://genic-web.com/articles/3336992",
    },
    {
      date: "2020.11.27",
      media: "NHK 大学生とつくる就活応援ニュースゼミ",
      title: "教えて先輩！“シェア伝道師” 石山アンジュさん「使命感」を持つこと",
      link: "https://www3.nhk.or.jp/news/special/news_seminar/senpai/senpai62/",
    },
    {
      date: "2020.11.22",
      media: "一橋大学 講演",
      title: "",
      link: "https://ikkyosai.com/visitor/51/news#news_6",
    },
    {
      date: "2020.11.22",
      media: "Tokyo Work Design Week 2020 登壇",
      title:
        "【都市から地方へ／参加無料】多拠点生活。やってみたら人生は変わるのか？ ～“西の軽井沢”こと岡山県真庭市で実験してみた～",
      link: "https://20201122n.peatix.com/",
    },
    {
      date: "2020.11.21",
      media: "Listen to Her ～今こそ聞きたいビジョナリー・ウーマンの話～ 登壇",
      title: "",
      link: "https://listentoheronline.peatix.com/view",
    },
    {
      date: "2020.11.19",
      media: "WOW！ BASE 登壇",
      title:
        "石山アンジュが語る「これからの時代のビジネスモデルと社会モデル」 〜シェアライフ-新しい社会の生き方〜",
      link: "https://www.wowbase.jp/event/2020-1119/",
    },
    {
      date: "2020.11.19",
      media: "テレビ朝日",
      title: "羽鳥慎一 モーニングショー出演",
      link: "https://www.tv-asahi.co.jp/m-show/",
    },
    {
      date: "2020.11.17",
      media: "NHK 大学生とつくる就活応援ニュースゼミ",
      title:
        "教えて先輩！“シェア伝道師” 石山アンジュさん「違和感」から始まった挑戦",
      link: "https://www3.nhk.or.jp/news/special/news_seminar/senpai/senpai61/",
    },
    {
      date: "2020.11.9",
      media: "Pixie Nest「New Normal時代のメンタルヘルス」登壇",
      title: "",
      link: "https://pixiedusttech.com/",
    },
    {
      date: "2020.11.9",
      media: "Woman type",
      title:
        "「豊かさとは、つながりの広さ」石山アンジュが多拠点生活で実践するサステナブルな生き方",
      link: "https://woman-type.jp/wt/feature/19115/",
    },
    {
      date: "2020.11.1",
      media: "朝日新聞朝刊 4週連続掲載",
      title: "仕事力 「働く」を考えるコラム「使命感を持ちチームと進む」",
      link: "https://info.asahi.com/shukatsu/asakyu/anjyu_--1.html",
    },
    {
      date: "2020.10.31",
      media: "女の転職typeイベント登壇",
      title: "シェアと考える ウィズコロナ時代を豊かに生きるモノサシ",
      link: "https://woman-type.jp/s/event/",
    },
    {
      date: "2020.10.30",
      media: "adtech Tokyo登壇",
      title: "シェアリングエコノミーの強さとは",
      link: "https://adtech-tokyo.com/ja/session/session.html?num=NX1_year2020",
    },
    {
      date: "2020.10.27",
      media: "CAST People#13_渋谷 と 家族を実験する人たち",
      title: "拡張家族「Cift」が3年間の共同生活で育んだものとは",
      link: "https://shibuyacast.jp/journal/detail/people-cift/",
    },
    {
      date: "2020.10.26",
      media: "",
      title: "AERA「21世紀を作るニッポン人名鑑」",
      link: "https://publications.asahi.com/ecs/12.shtml",
    },
    {
      date: "2020.10.16",
      media: "ロート製薬 社内イベント登壇",
      title: "",
      link: "",
    },
    {
      date: "2020.10.15",
      media: "npdateNOW 20 登壇",
      title:
        "ネクストノーマル時代の「消費」～コロナ時代における消費と信頼について考える～",
      link: "https://www.wingarc.com/updata/online/2020/speakers/",
    },
    {
      date: "2020.10.2",
      media: "Gaiaxブログ",
      title:
        "「女性活躍」の言葉に違和感はある。だけど、女性が生きにくい世の中は間違っている。",
      link: "https://www.gaiax.co.jp/blog/womenomics/",
    },
    {
      date: "2020.9.29",
      media: "Start Venture Festival 登壇",
      title: "これからの時代の歩き方 ~ 将来の衣食住を作る ~",
      link: "https://svf.tokyo/#",
    },
    {
      date: "2020.9.26",
      media: "地域政策デザインスクール 登壇",
      title: "",
      link: "",
    },
    {
      date: "2020.9.20",
      media: "Harper's BAZAAR 掲載",
      title: "「コロナ時代に考える、豊かさの新しい指針」",
      link: "https://www.hearst.co.jp/brands/harpersbazaar/",
    },
    {
      date: "2020.9.17",
      media: "PA サミット 登壇",
      title:
        "世論喚起とPA 〜パブリックアフェアーズ × PR/世論喚起による効果最大化〜",
      link: "https://pajapan.or.jp/pasummit2020/schedule/session-5/",
    },
    {
      date: "2020.9.16",
      media: "日本経済新聞 掲載",
      title:
        "<Nextストーリー ミレニアル新常態の主役>(2) 奮い立つシェアエコ伝道師 コロナ下「日本を変える」闘い",
      link:
        "https://www.nikkei.com/nkd/company/article/?DisplayType=1&ng=DGKKZO63887840V10C20A9TJ2000&scode=3900",
    },
    {
      date: "2020.9.13",
      media: "日本経済新聞 電子版 掲載",
      title:
        "今こそ日本を作り替えよう シェアエコの伝道師が動く ミレニアル　新常態の主役（2）",
      link:
        "https://www.nikkei.com/nkd/company/article/?DisplayType=1&ng=DGXMZO63663320Q0A910C2000000&scode=3900&ba=1",
    },
    {
      date: "2020.9.9",
      media: "ＬＣＡ日本フォーラム 登壇",
      title: "サーキュラーエコノミーとシェアリングエコノミー",
      link: "https://lca-forum.org/seminar/list.html",
    },
    {
      date: "2020.9.3",
      media: "Tokyo FM 未来授業",
      title: "わたしからわたしたちへ",
      link: "https://www.tfm.co.jp/future/detail/26406/",
    },
    {
      date: "2020.9.2",
      media: "Tokyo FM 未来授業",
      title: "シェアの未来、拡張する家族",
      link: "https://www.tfm.co.jp/future/detail/26405/",
    },
    {
      date: "2020.9.1",
      media: "Tokyo FM 未来授業",
      title: "シェアの思想、信頼のものさし",
      link: "https://www.tfm.co.jp/future/detail/26403/",
    },
    {
      date: "2020.9.1",
      media: "日経SDGsフォーラム シンポジウム 登壇",
      title: "",
      link: "https://events.nikkei.co.jp/28136/",
    },
    {
      date: "2020.8.31",
      media: "Tokyo FM 未来授業",
      title:
        "石山アンジュ 第1回 未来授業 Vol.1902 コロナの時代のシェアリングエコノミー",
      link: "https://www.tfm.co.jp/future/detail/26402/",
    },
    {
      date: "2020.8.31",
      media: "マネクリ掲載（マネックス証券メディア）",
      title:
        "石山アンジュ氏「ポストコロナ時代のシェアリングエコノミーとお金のあり方」",
      link: "https://media.monex.co.jp/articles/-/14891",
    },
    {
      date: "2020.8.23",
      media: "SUMMIT by WHERE 登壇",
      title: "",
      link: "https://summit.where.inc/",
    },
    {
      date: "2020.8.13",
      media: "中学受験ナビ",
      title:
        "シェアリングエコノミーの伝道師・石山アンジュさんを育んだ母の哲学と拡張家族｜「自分のやりたい！」がある子はどう育ったのか",
      link: "https://katekyo.mynavi.jp/juken/19195",
    },
    {
      date: "2020.8.6",
      media: "TBSラジオ 「ACTION」",
      title:
        "家族みたいなコミュニティーで生活する「シェアリングエコノミー」について",
      link: "https://www.tbsradio.jp/507504",
    },
    {
      date: "2020.8.5",
      media: "未来想像WEBマガジン",
      title:
        "大量生産大量消費時代のその先へ。シェアリングエコノミーの社会とは？",
      link: "https://miraisozo.mizuhobank.co.jp/people/80136",
    },
    {
      date: "2020.7.30",
      media: "Infinity Ventures Summit 登壇",
      title: "これからのコミュニティの話をしよう",
      link: "https://www.ivs.events/timetable",
    },
    {
      date: "2020.7.22",
      media: "GLOBAL EDGE 掲載",
      title: "Opinion Fileシェアライフを実践して手にした「家族」との幸せ",
      link: "https://www.jpower.co.jp/ge/62/opinion/index_01.html",
    },
    {
      date: "2020.7.14",
      media:
        "災害対策などポストコロナの地域課題解決を目指しシェアリングシティ推進協議会を設立。",
      title:
        "千葉市、神戸市、福岡市、渋谷区など、7自治体ほか民間企業12社ら参画。",
      link: "https://prtimes.jp/main/html/rd/p/000000043.000022734.html",
    },
    {
      date: "2020.7.13",
      media: "一般社団法人PublicMeetsInnovation主催",
      title:
        "「NEW PUBLIC 〜ルールはつくれる、変えられる。イノベーションを社会実装するために 」",
      link: "https://pmi-20200713.peatix.com/?lang=ja",
    },
    {
      date: "2020.7.11",
      media: "J-WAVE",
      title: "ANA WORLD AIR CURRENT Podcast 出演",
      link: "https://www.j-wave.co.jp/original/worldaircurrent/200711.html",
    },
    {
      date: "2020.7.10",
      media: "スマートシティ・インスティテュート",
      title: "「シェアリングエコノミーの今と未来」オンライン登壇",
      link: "https://www.youtube.com/watch?v=lQETYhFb9mo",
    },
    {
      date: "2020.6.26",
      media:
        "一般社団法人日本ショッピングセンター発行「SC JAPAN TODAY」7・8月号 掲載",
      title:
        "【注目のビジネスモデル⑦　シェアリングエコノミー】“共有”で新しい経済社会をつくる「シェアリングエコノミー」",
      link: "http://www.jcsc.or.jp/cat_magazine/p_20200626_39423",
    },
    {
      date: "2020.6.21",
      media: "NHK クローズアップ現代&ドキュメンタリー 動画掲載",
      title: "",
      link: "https://www.facebook.com/NHKgendai/videos/289169718944288/",
    },
    {
      date: "2020.6.15",
      media: "Fintech Journal レポート掲載",
      title:
        "“信頼”の概念が変わった？ シェア経済に見る「アフターコロナ」で注力すべきこと",
      link: "https://www.sbbit.jp/article/fj/38113",
    },
    {
      date: "2020.6.12",
      media: "ダイヤモンドオンライン掲載",
      title:
        "新型コロナで“明暗”分かれた「シェアリングサービス」、国内4社の現状",
      link: "https://diamond.jp/articles/-/240075?page=7",
    },
    {
      date: "2020.6.10",
      media: "FUTURE IS NOW 掲載",
      title:
        "人と人とが、意識で繋がる。 「Cift(シフト)」が考える、未来版“家族”のカタチ",
      link: "https://fin.miraiteiban.jp/cift/",
    },
    {
      date: "2020.6.6",
      media:
        "TBS NEWS Dooo「“人生を変えた本”総集編スペシャル１」出演（再放送）",
      title: "",
      link: "https://www.youtube.com/watch?v=GGU7edqr3YI&feature=youtu.be",
    },
    {
      date: "2020.6.5",
      media:
        "世界で同時にミライを考えよう - みんなでプラス - NHK クローズアップ現代 Web掲載",
      title: "",
      link: "https://www.nhk.or.jp/gendai/comment/0008/topic039.html",
    },
    {
      date: "2020.5.29",
      media: "東京新聞 掲載",
      title:
        "ウーバーイーツ人気　エアビーは落ち込む　コロナでシェアサービスに明暗",
      link: "https://www.tokyo-np.co.jp/article/31874?rct=economics",
    },
    {
      date: "2020.5.29",
      media: "Share.jp 掲載",
      title: "石山アンジュさんに聞く コロナ禍 でのシェアリングエコノミー事情",
      link: "https://share.jp/column/covid-19/",
    },
    {
      date: "2020.5.28",
      media: "Forebes Japan 掲載",
      title:
        "次の50年を生きる当事者として。コロナ危機を「機会」にするムーブメント",
      link: "https://forbesjapan.com/articles/detail/34775",
    },
    {
      date: "2020.5.26",
      media: "NHKラジオ 武内陶子のごごカフェ出演",
      title: "石山アンジュさん　新しい家族のカタチ",
      link: "https://www.nhk.or.jp/radio/magazine/detail/gogocafe20200526.html",
    },
    {
      date: "2020.5.21",
      media: "PR3.0 Conference ［ TECHNOLOGY ］登壇",
      title:
        "テクノロジーを活かしきるために。これからのパブリックアフェアーズを考える",
      link: "https://pr-update.pr-table.com/conference/technology/",
    },
    {
      date: "2020.5.16",
      media: "NewsPicks 「モビエボ」出演",
      title: "山里亮太と考える「アフターコロナの旅はどうなる？」",
      link: "https://www.youtube.com/watch?v=s7dS6POZ4iA",
    },
    {
      date: "2020.5.13",
      media: "Code for Japan 主催",
      title: "接触確認アプリ勉強会 登壇",
      link: "https://www.code4japan.org/news/20200513-event",
    },
    {
      date: "2020.5.1",
      media: "Fintech協会主催オンラインイベント 登壇",
      title: "アフターコロナの世界に向けて",
      link: "https://faj05011400.peatix.com/?lang=ja",
    },
    {
      date: "2020.4.21",
      media:
        "日本デジタルトランスフォーメーション推進協会、新たにアドバイザー3名招聘。",
      title:
        "デジタルトランスフォーメーション推進で多様な人材が活躍できる社会へアップデート［一般社団法人日本デジタルトランスフォーメーション推進協会］",
      link: "https://www.value-press.com/pressrelease/240874",
    },
    {
      date: "2020.4.23",
      media:
        "【今こそ読みたい】日本の名だたる経営者達は、いかに苦境を乗り越えたか？66人インタビューの全てを掲載！",
      title: "",
      link: "https://tokyo-calendar.jp/article/18174?page=12",
    },
    {
      date: "2020.3.29",
      media: "中国新聞セレクト 掲載",
      title: "新型コロナ一斉休校　助け合いアプリ利用拡大　子守や送迎　家事も",
      link: "",
    },
    {
      date: "2020.3.28",
      media: "共同テレビジョン",
      title: "～マイ・ウェイ・バラエティ～この生き方、天才かも？ 出演",
      link: "https://www.tokai-tv.com/io/mywayvariety/",
    },
    {
      date: "2020.3.25",
      media: "WEEKLY OCHIAI シーズン4 レギュラーMC出演",
      title: '落合陽一【緊急検証】東京封鎖"ロックダウン"は起こるか？',
      link: "https://newspicks.com/movie-series/28?movieId=607",
    },
    {
      date: "2020.3.25",
      media: "中部経済新聞 掲載",
      title: "アプリ使って助け合い／休校中の子守、家事…",
      link: "",
    },
    {
      date: "2020.3.24",
      media: "徳島新聞夕刊 掲載",
      title:
        "休校中の子守や家事・・・　個人間依頼アプリ人気　新規登録急増　運営業者、家庭支援を仲介",
      link: "",
    },
    {
      date: "2020.3.23",
      media: "熊本日日新聞夕刊 掲載",
      title:
        "「子守お願い」アプリで依頼　休校中の家事支援　個人間でマッチング　新型コロナウイルス",
      link: "",
    },
    {
      date: "2020.3.23",
      media: "日本経済新聞 　電子版掲載",
      title:
        "「個人が支え合う真のシェア社会に」石山アンジュ氏－シェアリングエコノミー協会事務局長",
      link: "https://r.nikkei.com/article/DGXMZO57050160Q0A320C2000000?s=4",
    },
    {
      date: "2020.3.22",
      media: "千葉日報 掲載",
      title:
        "一斉休校中の子守、家事…　アプリ使って助け合い　“ご近所”会員紹介　新規登録者が急増　【新型コロナ】",
      link: "",
    },
    {
      date: "2020.3.18",
      media: "共同通信ニュース",
      title: "アプリ使って助け合い―休校中の子守、家事依頼し合う",
      link: "",
    },
    {
      date: "2020.3.18",
      media: "WEEKLY OCHIAI シーズン4 レギュラーMC出演",
      title: "「どうなる？東京オリンピック」落合陽一×三浦瑠麗×猪瀬直樹",
      link: "https://newspicks.com/movie-series/28?movieId=587",
    },
    {
      date: "2020.3.4",
      media: "WEEKLY OCHIAI シーズン4 レギュラーMC出演",
      title: "これからの「性愛・セックス」を語る",
      link: "https://newspicks.com/movie-series/28?movieId=559",
    },
    {
      date: "2020.3.4",
      media: "日経doors連載掲載",
      title:
        "石山アンジュ「血縁に頼らない家族」を広げる挑戦は続く 最終回　他者を信じる心の垣根を下げればシェアライフは楽しめる",
      link: "https://doors.nikkei.com/atcl/column/19/101100126/030200010/",
    },
    {
      date: "2020.2.25",
      media: "日本経済新聞 掲載",
      title: "",
      link: "",
    },
    {
      date: "2020.2.25",
      media:
        "日本経済新聞社主催 特別シンポジウム　日本・バルセロナ スマートシティフォーラムに登壇",
      title: "バルセロナ市が目指す持続可能なスマートシティーのあり方と取り組み",
      link: "https://dime.jp/genre/861138/2/",
    },
    {
      date: "2020.2.21",
      media: "日本経済新聞 掲載",
      title: "首都圏で広がるシェアサービス、自治体も積極活用 ＃ハッシュタグ",
      link: "https://www.nikkei.com/article/DGXMZO55855980Q0A220C2L83000/",
    },
    {
      date: "2020.2.21",
      media: "POOLO イベント登壇 レポート",
      title:
        "「シェアで世界はどう変わる？」【ゲスト：石山アンジュさん＆工藤慎一さん】",
      link: "https://poolo.tabippo.net/article/20200122event/",
    },
    {
      date: "2020.2.19",
      media: "WEEKLY OCHIAI シーズン4 MC出演",
      title: "落合陽一「結婚は幸せ？独身じゃダメ？これからの結婚を語ろう」",
      link: "https://newspicks.com/movie-series/28?movieId=537",
    },
    {
      date: "2020.2.19",
      media: "テレビ朝日 【大下容子ワイド！スクランブル】スタジオ出演",
      title:
        "＜Ｔｈｅ　Ｌｉｆｅ　相談所＞シニア世代のホストが提供するシェアリングエコノミーサービス",
      link: "https://www.tv-asahi.co.jp/scramble/",
    },
    {
      date: "2020.2.14",
      media:
        "シェアリングエコノミーシンポジウム「東京2020大会における私なりのおもてなし～出会いの感動を千葉で・・・世界とつながるホームシェア～」登壇",
      title: "",
      link:
        "https://www.city.chiba.jp/sogoseisaku/sogoseisaku/tokku/sharing-economy_symposium2019.html",
    },
    {
      date: "2020.2.13",
      media: "四国生産性本部・第74期日本のトップを囲む懇談会 基調講演",
      title: "",
      link: "",
    },
    {
      date: "2020.2.12",
      media: "WEEKLY OCHIAI シーズン4 MC出演",
      title: "落合陽一「女性リーダーの増加が日本再生の鍵となる？」",
      link: "https://newspicks.com/movie-series/28?movieId=520",
    },
    {
      date: "2020.2.12",
      media: "テレビ朝日 【大下容子ワイド！スクランブル】スタジオ出演",
      title: "＜Ｔｈｅ　Ｌｉｆｅ　相談所＞時計も家電も…預けて稼ぐシェア活用術",
      link: "https://www.tv-asahi.co.jp/scramble/",
    },
    {
      date: "2020.2.9",
      media: "B-biz レポート掲載",
      title: "別府の創業コミュニティ「くまはち温泉」”きめの湯”　開催！",
      link: "https://www.b-bizlink.or.jp/news/kumahachi-kimenoyu",
    },
    {
      date: "2020.2.9",
      media: "中日新聞掲載",
      title:
        "＜シェアリングエコノミー＞後編　社会変える力になるか:Dig:中日新聞(CHUNICHI Web) ",
      link: "",
    },
    {
      date: "2020.2.6",
      media: "Share.jp 掲載",
      title:
        "石山アンジュさんに聞く！2020年、日本のシェアリングエコノミーのトレンドとは？",
      link: "https://share.jp/sharing-economy/ishiyama2020/",
    },
    {
      date: "2020.2.5",
      media: "WEEKLY OCHIAI シーズン4 レギュラーMC出演",
      title: "落合陽一×りゅうちぇる『これからのメディアについて語ろう』",
      link: "https://newspicks.com/movie-series/28?movieId=514",
    },
    {
      date: "2020.2.3",
      media: "渋谷のラジオ出演",
      title: "",
      link: "",
    },
    {
      date: "2020.2.3",
      media: "徳島県主催 ICT（愛して）とくしまフォーラム2020 にて 基調講演",
      title:
        "【開催レポート】ICT（愛して）とくしまフォーラム2020の開催について ",
      link: "https://www.pref.tokushima.lg.jp/ippannokata/sangyo/ict/5031772/",
    },
    {
      date: "2020.2.3",
      media: "日経doors 連載",
      title: "多拠点生活も格安に　手ぶら旅行も　最新シェアサービス",
      link:
        "https://doors.nikkei.com/atcl/column/19/101100126/013000009/?n_cid=nbpdrs_twbn",
    },
  ];

  const tempMediaArray = [];
  const tempNewsArray = [];

  const [dlMedia, setMedia] = useState([]);
  const [dlNews, setNews] = useState([]);
  const [overrideNumber, setOverrideNumber] = useState(0);

  const client = createClient({
    serviceDomain: "anjuofficial",
    apiKey: "e4ba756893834a9e87f96ab576f024029ec4",
  });

  useEffect(() => {
    client
      .get({
        endpoint: "update_news",
        queries: { limit: 1000 },
        useGlobalDraftKey: false, // This is an option if your have set the globalDraftKey. Default value true.
      })
      .then((res) => {
        res.contents.map((c) => {
          const tempObj = {
            date: c.date,
            media: c.media,
            title: c.title,
            url: c.link,
            img: c.img,
            isPin: c.isPin,
          };
          tempNewsArray.push(tempObj);
        });
        console.log("tempNewsArray", tempNewsArray);
        setNews(() => [tempNewsArray, news].flat());
      });
    client
      .get({
        endpoint: "media",
        queries: { limit: 1000 },
        useGlobalDraftKey: false, // This is an option if your have set the globalDraftKey. Default value true.
      })
      .then((res) => {
        res.contents.map((c) => {
          const tempObj = {
            date: c.date,
            media: c.media,
            title: c.title,
            url: c.url,
            img: c.img,
            isPin: c.isPin,
          };
          tempMediaArray.push(tempObj);
        });
        setMedia(() => [tempMediaArray, content].flat());
      });
  }, [setMedia]);

  const combinedData = dlMedia?.concat(dlNews).sort((a, b) => {
    // const dateRegex = /\d{4}\.\d{1,2}\.\d{1,2}/g;
    // const dateA = new Date(a.date.match(dateRegex));
    // const dateB = new Date(b.date.match(dateRegex));
    // return dateA < dateB ? 1 : -1;
    // return a.date > b.date ? 1 : -1;
    const dateRegex = /\d{4}\.\d{1,2}\.\d{1,2}/g;

    // 'match'の結果がnullでないことを確認
    const matchA = a.date.match(dateRegex);
    const matchB = b.date.match(dateRegex);
    if (!matchA || !matchB) return 0;

    const dateA = new Date(matchA[0]);
    const dateB = new Date(matchB[0]);

    // 'getTime()'を使用して比較
    return dateB.getTime() - dateA.getTime();
  });

  console.log({ combinedData });

  const contentFilter = combinedData?.filter((el) => el?.isPin === true);
  const contentUnFilter = combinedData?.filter((el) => el?.isPin !== true);
  const contentIsPinTop = contentFilter?.concat(contentUnFilter);

  const overridePC = isTop ? contentIsPinTop?.slice(0, 9) : contentIsPinTop;
  const overrideSP = isTop ? contentIsPinTop?.slice(0, 5) : contentIsPinTop;

  console.log({ contentIsPinTop });
  const classes = ButtonStyles();

  return (
    <>
      <Box
        width="90%"
        m={"auto"}
        mt={isTop ? 20 : 5}
        maxWidth="1200px"
        id="media"
        mb={18}
        pt="40px"
      >
        <WrapTypo
          fs="36px"
          color="rgba(128, 161, 221, 1)"
          mt="32px"
          mb="32px"
          ml="20px"
        >
          MEDIA and NEWS
        </WrapTypo>
        <Hidden smDown>
          <Grid container justify="center">
            {overridePC?.slice(0, overrideNumber * 6 + 9).map((c) => {
              return (
                <Grid item xs={12} md={4}>
                  <Box width="90%" my={3} margin="auto">
                    {c?.url ? (
                      <Link
                        to={c.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {c?.img?.childImageSharp ? (
                          <Img
                            style={{
                              width: "100%",
                              margin: "auto",
                              height: "250px",
                              objectFit: "cover",
                            }}
                            fluid={c.img.childImageSharp.fluid}
                          />
                        ) : c?.img ? (
                          <img
                            src={c.img.url}
                            style={{
                              width: "100%",
                              height: "250px",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <img
                            src={noImage}
                            style={{
                              width: "100%",
                              height: "250px",
                              objectFit: "cover",
                            }}
                          />
                        )}
                        <WrapTypo
                          Notosans
                          mt="8px"
                          mb="32px"
                          fs="14px"
                          color="#717171"
                        >
                          {c?.date}|{c?.media}
                          <br />
                          {c?.title}
                        </WrapTypo>
                      </Link>
                    ) : (
                      <>
                        {c?.img?.childImageSharp ? (
                          <Img
                            style={{
                              width: "100%",
                              margin: "auto",
                              height: "250px",
                              objectFit: "cover",
                            }}
                            fluid={c.img.childImageSharp.fluid}
                          />
                        ) : c?.img ? (
                          <img
                            src={c.img.url}
                            style={{
                              width: "100%",
                              height: "250px",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <img
                            src={noImage}
                            style={{
                              width: "100%",
                              height: "250px",
                              objectFit: "cover",
                            }}
                          />
                        )}
                        <WrapTypo
                          Notosans
                          mt="8px"
                          mb="32px"
                          fs="14px"
                          color="#717171"
                        >
                          {c?.date}|{c?.media}
                          <br />
                          {c?.title}
                        </WrapTypo>
                      </>
                    )}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          {isTop ? (
            <Box m={"auto"} width="50%" textAlign="center">
              <Link to="/media">
                <Button
                  style={{
                    border: "solid 1px rgba(128, 161, 221, 1)",
                    backgroundColor: "rgba(128, 161, 221, 1)",
                  }}
                >
                  <WrapTypo
                    Notosans
                    fs="14px"
                    mt="8px"
                    mb="8px"
                    ml="24px"
                    mr="24px"
                    color="#fff"
                  >
                    全て見る
                  </WrapTypo>
                </Button>
              </Link>
            </Box>
          ) : (
            <>
              <Box
                maxWidth="1300px"
                width="95%"
                m="auto"
                position="fixed"
                bottom={["24px", "40px", "40px", "40px"]}
                left="0"
                right="0"
              >
                <Link to="#">
                  <Box
                    width={["40px", "40px", "50px", "50px"]}
                    height={["40px", "40px", "50px", "50px"]}
                    bgcolor="#FFFFFF"
                    borderRadius="50%"
                  >
                    <KeyboardArrowUpIcon className={classes.icon} />
                  </Box>
                </Link>
              </Box>
              <Box
                m={"7% auto"}
                width="50%"
                textAlign="center"
                display={
                  overridePC.length > overrideNumber * 6 + 9 ? "block" : "none"
                }
              >
                <Button
                  onClick={() => {
                    setOverrideNumber((overrideNumber) => {
                      return overrideNumber + 1;
                    });
                  }}
                  style={{
                    border: "solid 1px rgba(128, 161, 221, 1)",
                    backgroundColor: "rgba(128, 161, 221, 1)",
                  }}
                >
                  <WrapTypo
                    Notosans
                    fs="14px"
                    mt="8px"
                    mb="8px"
                    ml="24px"
                    mr="24px"
                    color="#fff"
                  >
                    次の6件表示
                  </WrapTypo>
                </Button>
              </Box>
              {/* <Box m={"auto"} width="50%" textAlign="center">
                <Link to="/">
                  <Button
                    style={{
                      border: "solid 1px rgba(128, 161, 221, 1)",
                      backgroundColor: "rgba(128, 161, 221, 1)",
                    }}
                  >
                    <WrapTypo
                      Notosans
                      fs="14px"
                      mt="8px"
                      mb="8px"
                      ml="24px"
                      mr="24px"
                      color="#fff"
                    >
                      TOPへ戻る
                    </WrapTypo>
                  </Button>
                </Link>
              </Box> */}
            </>
          )}
        </Hidden>
        <Hidden mdUp>
          <Grid container justify="center">
            {overrideSP?.slice(0, overrideNumber * 6 + 5).map((c) => {
              return (
                <Grid item xs={12} md={4}>
                  <Box width="90%" my={3} margin="auto">
                    {c?.url ? (
                      <Link
                        to={c.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {c?.img?.childImageSharp ? (
                          <Img
                            style={{
                              width: "100%",
                              margin: "auto",
                              height: "250px",
                              objectFit: "cover",
                            }}
                            fluid={c.img.childImageSharp.fluid}
                          />
                        ) : c?.img ? (
                          <img
                            src={c.img.url}
                            style={{
                              width: "100%",
                              height: "250px",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <img
                            src={noImage}
                            style={{
                              width: "100%",
                              height: "250px",
                              objectFit: "cover",
                            }}
                          />
                        )}
                        <WrapTypo
                          Notosans
                          mt="8px"
                          mb="32px"
                          fs="14px"
                          color="#717171"
                        >
                          {c?.date}|{c?.media}
                          <br />
                          {c?.title}
                        </WrapTypo>
                      </Link>
                    ) : (
                      <>
                        {c?.img?.childImageSharp ? (
                          <Img
                            style={{
                              width: "100%",
                              margin: "auto",
                              height: "250px",
                              objectFit: "cover",
                            }}
                            fluid={c.img.childImageSharp.fluid}
                          />
                        ) : c?.img ? (
                          <img
                            src={c.img.url}
                            style={{
                              width: "100%",
                              height: "250px",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <img
                            src={noImage}
                            style={{
                              width: "100%",
                              height: "250px",
                              objectFit: "cover",
                            }}
                          />
                        )}
                        <WrapTypo
                          Notosans
                          mt="8px"
                          mb="32px"
                          fs="14px"
                          color="#717171"
                        >
                          {c?.date}|{c?.media}
                          <br />
                          {c?.title}
                        </WrapTypo>
                      </>
                    )}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          {isTop ? (
            <Box m={"auto"} width="50%" textAlign="center">
              <Link to="/media">
                <Button
                  style={{
                    border: "solid 1px rgba(128, 161, 221, 1)",
                    backgroundColor: "rgba(128, 161, 221, 1)",
                  }}
                >
                  <WrapTypo
                    Notosans
                    fs="14px"
                    mt="8px"
                    mb="8px"
                    ml="24px"
                    mr="24px"
                    color="#fff"
                  >
                    全て見る
                  </WrapTypo>
                </Button>
              </Link>
            </Box>
          ) : (
            <>
              <Box
                maxWidth="1300px"
                width="95%"
                m="auto"
                position="fixed"
                bottom={["24px", "40px", "40px", "40px"]}
                left="0"
                right="0"
              >
                <Link to="#">
                  <Box
                    width={["40px", "40px", "50px", "50px"]}
                    height={["40px", "40px", "50px", "50px"]}
                    bgcolor="#FFFFFF"
                    borderRadius="50%"
                  >
                    <KeyboardArrowUpIcon className={classes.icon} />
                  </Box>
                </Link>
              </Box>
              <Box
                m={"7% auto"}
                width="50%"
                textAlign="center"
                display={
                  overrideSP.length > overrideNumber * 6 + 5 ? "block" : "none"
                }
              >
                <Button
                  onClick={() => {
                    setOverrideNumber((overrideNumber) => {
                      return overrideNumber + 1;
                    });
                  }}
                  style={{
                    border: "solid 1px rgba(128, 161, 221, 1)",
                    backgroundColor: "rgba(128, 161, 221, 1)",
                  }}
                >
                  <WrapTypo
                    Notosans
                    fs="14px"
                    mt="8px"
                    mb="8px"
                    ml="24px"
                    mr="24px"
                    color="#fff"
                  >
                    次の6件表示
                  </WrapTypo>
                </Button>
              </Box>
              {/* <Box m={"auto"} width="50%" textAlign="center">
                <Link to="/">
                  <Button
                    style={{
                      border: "solid 1px rgba(128, 161, 221, 1)",
                      backgroundColor: "rgba(128, 161, 221, 1)",
                    }}
                  >
                    <WrapTypo
                      Notosans
                      fs="14px"
                      mt="8px"
                      mb="8px"
                      ml="24px"
                      mr="24px"
                      color="#fff"
                    >
                      TOPへ戻る
                    </WrapTypo>
                  </Button>
                </Link>
              </Box> */}
            </>
          )}
        </Hidden>
      </Box>
    </>
  );
};

export default Profile;

const ButtonStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 50,
    color: "rgba(128, 161, 221, 1)",
    [theme.breakpoints.down("sm")]: {
      fontSize: 40,
    },
  },
}));
